@import './config.scss';
@import './animations.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

$logoPaddingY: $globalPadding * 0.75;
$headerHeight: $logoHeight + ($logoPaddingY * 2);

:root {
  color-scheme: dark;
}

body, html {
  margin: 0;
  padding: 0;
  background-color: $colorBackground;
  font-family: $globalFontFamily;
  font-weight: $globalFontWeight;
  color: $colorText;
}

body {
  background-image: url('../assets/blob-bottom.png');
  background-repeat: no-repeat;
  background-position: bottom left;
}

a {
  font-weight: bolder;

  &:hover {
    text-decoration: underline;
    text-decoration-color: $colorHighlight1;
  }
}

// p, h2 {
//   mix-blend-mode: difference;
// }

#website-header {
  position: absolute;
  z-index: $zIndexLogo;
  top: 0;
  left: 0;
  width: 100%;
  height: $headerHeight;
  padding: $logoPaddingY $globalPadding;
  // pointer-events: none;
  // background-color: black;

  > a {
    display: inline-block;
  }

  img {
    position: relative;
    top: -2px;
    height: $logoHeight;
  }
}

#website-main {
  padding-top: $headerHeight;
  overflow: hidden;
}

#website-footer {
  padding: $globalPadding;
  margin-top: $globalPadding;
  mix-blend-mode: difference;

  // p {
  //   opacity: 0.75;
  // }
}

.blob-right {
  max-width: 75%;
  min-width: 60%;
  top: -150px;
  z-index: 0;

  @media (max-width: 767px) {
    top: -100px;
    width: 120%;
    max-width: 120%;
  }
}

#main-experience {
  width: 100%;
  height: 80vh;
  background-color: black;
}

.metahand-container {
  position: relative;
  font-family: $globalFontFamily;
  font-size: 16px;
  font-weight: normal;
  overflow: hidden;

  input, select, button {
    font-family: inherit;
  }

  > #metahand-stats > * {
    z-index: $zIndexStats !important;
    top: ($logoHeight + ($globalPadding * 1.5)) !important;
    left: ($globalPadding * 0.5) !important;
  }

  > .app-canvas, > .video-container.fullscreen-under, > .full-message {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  > .app-canvas {
    z-index: $zIndexCanvas;
    width: 100% !important;
    height: 100% !important;
  }

  > .video-container {
    &.fullscreen-under {
      z-index: $zIndexCanvas - 2;
    }
    &.floating-above {
      position: absolute;
      z-index: $zIndexCamera;
      bottom: $globalPadding;
      right: $globalPadding;
      width: 25vw;
      height: 25vw;
      max-width: 128px;
      max-height: 128px;
      overflow: hidden;
      border: 1px solid #333;
      background-color: $colorBackground;
      border-radius: $globalRoundness;
      box-shadow: 0px 10px 10px rgba(black, 0.25);
    }
  }

  > .full-message {
    z-index: $zIndexMessages;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $globalPadding;
    box-sizing: border-box;
    font-size: 1.25rem;
    line-height: 1.5em;
    color: $colorText;
    text-align: center;
    text-shadow: 1px 1px black;
    transition: opacity 0.75s ease-in-out;
  }

  > div.full-message {
    pointer-events: none;
  }

  > .user-action-btn {
    z-index: $zIndexUserActions;
    flex-flow: column;
    border: none;
    background-color: rgba(black, 0.25);
    // backdrop-filter: blur(3px) brightness(0.9);
    cursor: pointer;

    > span {
      display: inline-block;
      border-radius: $globalRoundness * 2;
    }

    > .txt-details {
      margin-bottom: $globalPadding;
      font-size: 1.5rem;
      line-height: 1.25em;
    }

    > .txt-button {
      padding: $globalPadding ($globalPadding * 2);
      border: 1px solid black;
      background-color: white;
      font-size: 1.75rem;
      line-height: 1.125em;
      color: #222222;
      // box-shadow: 1px 3px 10px rgba(black, 0.5);
      text-shadow: none;
      // text-shadow: 0px -1px rgba(black, 0.25), 0px 1px rgba(white, 0.25);
      border-radius: $globalRoundness * 2;
      animation: pulse-animation 0.75s ease-in-out infinite alternate;

      // note: not used
      // &.btn-primary {
      //   background-color: mix(#0adef0, #eb659e);
      //   background: linear-gradient(-45deg, #0adef0, #eb659e);
      // }

      // @media (min-height: 250px) {
      //   display: inline-block;
      //   position: relative;
      //   top: -2em;
      // }
    }
  }

  > #metahand-poses {
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: $zIndexPoses;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    padding: $globalPadding;
    color: $colorText;
    font-size: 3rem;
    font-family: sans-serif;
    pointer-events: none;

    > span {
      width: 1em;
      height: 1em;
      margin: 0px $globalPadding;
      text-align: center;
    }
  }

  > #metahand-version {
    position: absolute;
    z-index: $zIndexVersion;
    bottom: 0;
    left: 0;
    padding: 5px;
    font-family: monospace;
    font-size: .5rem;
    color: $colorText;
    text-shadow: 1px 1px black;
    opacity: 0;

    &:hover {
      opacity: 1;
    }
  }

  > #metahand-loader {
    top: 0%;
    height: 100%;
    font-size: 1.5rem;
    line-height: 1.5em;
  }
}

body.full-screen-demo-mode {
  #website-main {
    padding-top: 0px;
  }

  #main-experience {
    height: 100vh;
  }
}

body.god-mode {
  .metahand-container {
    > .app-canvas {
      position: fixed;
      pointer-events: none;
    }

    > .video-container {
      &.floating-above {
        position: fixed;
      }
    }

    .hand-position-tuto, > .user-action-btn, > #metahand-loader {
      z-index: $zIndexCanvas - 1 !important;
    }

    .hand-position-tuto {
      position: fixed !important;
    }

    > #metahand-poses {
      position: fixed;
    }
  }
}

.dg.ac {
  z-index: $zIndexDatGui !important;
}

.video-container {
  display: flex;
  align-items: center;
  justify-content: center;

  video {
    width: 100%;
    height: 100%;
    transition: object-position 0.5s ease-in-out;

    &.invert-horiz {
      transform: scaleX(-1);
    }

    &.size-contain {
      object-fit: contain;
    }

    &.size-cover {
      object-fit: cover;
    }
  }
}

.playlist-controls {
  position: absolute;
  z-index: $zIndexCanvas - 2;
  bottom: $globalPadding;
  left: $globalPadding;
  width: 59vw;
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $globalRoundness;
  overflow: hidden;

  > button {
    $bgColor: black;
    $txtColor: white;

    width: 50%;
    padding: $globalPadding * 0.5;
    border: 1px solid rgba($txtColor, 0.5);
    background-color: rgba($bgColor, 0.2);
    text-align: center;
    font-size: 1.25rem;
    color: $txtColor;
    backdrop-filter: blur(15px);

    &.left {
      border-right-width: 0px;
      border-top-left-radius: $globalRoundness;
      border-bottom-left-radius: $globalRoundness;
    }

    &.right {
      border-top-right-radius: $globalRoundness;
      border-bottom-right-radius: $globalRoundness;
    }

    &:hover {
      border-color: rgba($txtColor, 1);
      background-color: rgba($bgColor, 0.4);
    }
  }
}

.hand-position-tuto {
  flex-wrap: wrap !important;
  overflow: hidden;
  // pointer-events: all !important;
  z-index: $zIndexCamera - 1 !important;
  background-color: rgba(#111, 0.6);
  backdrop-filter: blur(4px);
  user-select: none;

  > .hand-anim {
    position: relative;
    top: -5%;
    width: 300px;
    height: 300px;
    // filter: drop-shadow(0px 2px 20px rgba(black, 0.2));
    pointer-events: none;

    img {
      position: absolute;
      bottom: 0%;
      left: 0%;
      max-width: 100%;
      max-height: 100%;

      &.hand-wave {
        transform-origin: 60% 100%;
        animation: wave-animation 0.75s ease-in-out infinite alternate;
      }
    }

    &.rear-facing {
      img.hand-phone {
        z-index: 99;
        left: 20%;
      }
      img.hand-wave {
        z-index: 98;
        left: -20%;
        filter: brightness(85%);
      }
    }

    &.front-facing {
      img.hand-phone {
        z-index: 98;
        left: 16%;
        bottom: 5%;
        transform: scale(75%);
        filter: brightness(85%);
      }
      img.hand-wave {
        z-index: 99;
        left: -11%;
      }
    }

    .hand-instructions {
      position: absolute;
      top: 100%;
      left: 0%;
      z-index: 999;
      width: 100%;
      margin-top: 10px;
      text-align: center;
    }

    @media (max-width: 600px), (max-height: 600px) {
      width: 200px;
      height: 200px;
    }
  }

}
