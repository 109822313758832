@keyframes pulse-animation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

@keyframes wave-animation {
  0% {
    transform: rotateZ(15deg);
  }
  100% {
    transform: rotateZ(25deg);
  }
}
