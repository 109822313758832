$globalPadding: 20px;
$globalFontFamily: 'Roboto', sans-serif;
$globalFontWeight: 400;
$globalRoundness: 5px;
$colorBackground: #171717; // #171717 = bg-neutral-900
$colorText: #eee;

$colorHighlight1: #0adef0; // blue
$colorHighlight2: #eb659e; // pink

$zIndexLogo: 70;
$zIndexStats: 60;
$zIndexUserActions: 50;
$zIndexDatGui: 40;
$zIndexPoses: 30;
$zIndexMessages: 21;
$zIndexVersion: 20;
$zIndexCanvas: 10;

$zIndexCamera: $zIndexCanvas + 1;

$logoHeight: 50px;
